import {
  usePrivy,
  useWallets,
  getEmbeddedConnectedWallet,
} from "@privy-io/react-auth";
import { ethers } from "ethers";
import "../App.css";

function usePurchase() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);
  const ownerWallet = process.env.REACT_APP_OWNER_WALLET;

  const purchaseGold = async (ethAmount) => {
    try {
      if (embeddedWallet && embeddedWallet.isConnected()) {
        const weiValue = ethers.utils.parseEther(ethAmount);
        const hexWeiValue = ethers.utils.hexlify(weiValue);
        const transactionRequest = {
          to: ownerWallet,
          value: hexWeiValue,
        };

        const txUiConfig = {
          header: `Purchase Gold`,
          description: `Send ${ethAmount} ETH to purchase gold`,
          buttonText: `Send`,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashForPurchaseGold(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Purchase gold successful",
              data: backendResponse.data,
            };
          } else {
            return {
              success: false,
              message: "Verify transaction failed",
            };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send Transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashForPurchaseGold = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/trading/purchase-gold", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();
      console.log("Transaction Info:", data);

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Send transactionHash to backend failed:", error);
      return { success: false, message: error.message };
    }
  };

  const purchaseStarterPack = async (ethAmount, packId, packName) => {
    try {
      if (embeddedWallet && embeddedWallet.isConnected()) {
        const weiValue = ethers.utils.parseEther(ethAmount);
        const hexWeiValue = ethers.utils.hexlify(weiValue);
        const transactionRequest = {
          to: ownerWallet,
          value: hexWeiValue,
        };

        const txUiConfig = {
          header: `Purchase Super Starter Pack`,
          description: `Send ${ethAmount} ETH to purchase ${packName}`,
          buttonText: `Send`,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashForPurchaseStarterPack(
            txReceipt.transactionHash,
            packId
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Purchase Super Starter Pack successful",
              data: backendResponse.data,
            };
          } else {
            return {
              success: false,
              message: "Verify transaction failed",
            };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send Transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashForPurchaseStarterPack = async (txHash, packId) => {
    try {
      const response = await fetch(
        urlEndpoint + "/trading/purchase-starter-pack",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`, // Gửi JWT token trong header
          },
          body: JSON.stringify({ transactionHash: txHash, packId }),
        }
      );
      const data = await response.json();
      console.log("Transaction Info:", data);

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Send transactionHash to backend failed:", error);
      return { success: false, message: error.message };
    }
  };

  return { purchaseGold, purchaseStarterPack };
}

export default usePurchase;
