import {
  usePrivy,
  useWallets,
  getEmbeddedConnectedWallet,
} from "@privy-io/react-auth";
import "../App.css";
import { encodeFunctionData, createPublicClient, http } from "viem";
import { abiDailyCheckIn } from "../config/abi.contract";

function useDailyCheckIn() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const { sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const embeddedWallet = getEmbeddedConnectedWallet(wallets);
  const dailyCheckInContractAddress =
    process.env.REACT_APP_DAILY_CHECKIN_CONTRACT_ADDRESS;

  const dailyCheckIn = async () => {
    try {
      if (embeddedWallet && embeddedWallet.isConnected()) {

        const publicClient = createPublicClient({
          chain: process.env.REACT_APP_CHAIN_ID,
          transport: http(process.env.REACT_APP_RPC_BASE_SEPOLIA)
        });
  
        const checkInPrice = await publicClient.readContract({
          address: dailyCheckInContractAddress,
          abi: abiDailyCheckIn,
          functionName: 'checkInPrice',
        });

        const data = encodeFunctionData({
          abi: abiDailyCheckIn,
          functionName: "checkIn",
          args: [],
        });

        const txUiConfig = {
          header: `Daily`,
          description: `Daily checkin`,
          buttonText: `Checkin`,
        };

        // Construct transaction request
        const transactionRequest = {
          to: dailyCheckInContractAddress,
          data: data,
          value: checkInPrice,
        };

        const txReceipt = await sendTransaction(transactionRequest, txUiConfig);

        if (txReceipt.status === 1) {
          const backendResponse = await verifyTxHashAndProcessDailyCheckIn(
            txReceipt.transactionHash
          );

          if (backendResponse.success) {
            return {
              success: true,
              message: "Verify successful",
              data: backendResponse.data,
            };
          } else {
            return { success: false, message: "Verify transaction failed" };
          }
        } else {
          console.error("Transaction failed:", txReceipt);
          return {
            success: false,
            message: "Transaction failed on blockchain",
          };
        }
      } else {
        console.error(
          `Transaction failed: embedded wallet ${embeddedWallet} is not connected`
        );
        return {
          success: false,
          message: `Embedded wallet is not connected, please try again`,
        };
      }
    } catch (error) {
      console.error("Send transaction failed:", error);
      return { success: false, message: "Send Transaction failed" };
    }
  };

  const verifyTxHashAndProcessDailyCheckIn = async (txHash) => {
    try {
      const response = await fetch(urlEndpoint + "/user-quests/trigger-online-check-in", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("fishAuth")}`,
        },
        body: JSON.stringify({ transactionHash: txHash }),
      });
      const data = await response.json();

      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Error when sending transactionHash to backend:", error);
      return { success: false, message: error.message };
    }
  };

  return { dailyCheckIn };
}

export default useDailyCheckIn;
