import { usePrivy } from "@privy-io/react-auth";
import React, { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import "../App.css";
import GameComponent from "../GameComponent";
import useCustomLogout from "../hook/useLogout";

function Game() {
  const { authenticated } = usePrivy();
  const { customLogout } = useCustomLogout();
  const navigate = useNavigate();
  const isGuest = localStorage.getItem("isGuest") === "true";

  const isPWA = () => {
    return window.matchMedia("(display-mode: standalone)").matches;
  };

  const isRunOnTelegram = () => {
    return window.Telegram.WebView.initParams.tgWebAppData != undefined;
  };

  useEffect(() => {
    const performLogout = async () => {
      if (!isDesktop && !isPWA() && !isRunOnTelegram()) {
        await customLogout();
      }
    };
    performLogout();
  }, [isDesktop, isPWA, isRunOnTelegram]);

  // const numAccounts = user?.linkedAccounts?.length || 0;
  // const canRemoveAccount = numAccounts > 1;

  // const twitterSubject = user?.twitter?.subject || null;

  return authenticated || isGuest ? (
    <div className="Game-container">
      <div>
        <GameComponent /> {/* Component game */}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Game;
