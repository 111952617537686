import React, { useContext, useState } from "react";

const GlobalContext = React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  deferredPrompt: null,
  setDeferredPrompt: () => {},
  errorMessage: "",
  setErrorMessage: () => {},
});

export const GlobalProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        isAuthenticated,
        errorMessage,
        deferredPrompt,
        setIsAuthenticated,
        setErrorMessage,
        setDeferredPrompt,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
