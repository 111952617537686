const SIGNING_DOMAIN_NAME = "FishSignatureMintNFT";
const SIGNING_DOMAIN_VERSION = "1";

class FishSignatureMinterNFT {
  constructor({ contract, signer }) {
    this.contract = contract;
    this.signer = signer;
  }

  async createReq(
    to,
    fishIds,
    tokenIds,
    quantities,
    validityStartTimestamp,
    validityEndTimestamp,
    uid
  ) {
    const req = {
      to,
      fishIds,
      tokenIds,
      quantities,
      validityStartTimestamp,
      validityEndTimestamp,
      uid,
    };

    // Define the EIP-712 domain and types
    const domain = await this._signingDomain();
    const types = {
      FishMintRequest: [
        { name: "to", type: "address" },
        { name: "fishIds", type: "bytes32[]" },
        { name: "tokenIds", type: "uint256[]" },
        { name: "quantities", type: "uint256[]" },
        { name: "validityStartTimestamp", type: "uint128" },
        { name: "validityEndTimestamp", type: "uint128" },
        { name: "uid", type: "bytes32" },
      ],
    };

    const signature = await this.signer._signTypedData(domain, types, req);
    return {
      req: { ...req },
      signature: signature,
    };
  }

  async _signingDomain() {
    if (this._domain != null) {
      return this._domain;
    }
    // TODO: chek again
    // const chainId = await this.contract.provider
    //   .getNetwork()
    //   .then((n) => n.chainId);


    this._domain = {
      name: SIGNING_DOMAIN_NAME,
      version: SIGNING_DOMAIN_VERSION,
      verifyingContract: this.contract.address,
      chainId: process.env.REACT_APP_CHAIN_ID,
    };
    return this._domain;
  }
}

module.exports = {
  FishSignatureMinterNFT,
};
