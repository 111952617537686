import { usePrivy } from "@privy-io/react-auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useGlobalContext } from "../context";

function useCustomLogout() {
  const { logout } = usePrivy();
  const { setIsAuthenticated } = useGlobalContext();
  const navigate = useNavigate();

  const customLogout = async () => {
    await logout();
    setIsAuthenticated(false);
    localStorage.setItem("isLoggedOut", true);
    localStorage.removeItem("fishAuth");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("twitter_access_token");
    localStorage.removeItem("twitter_refresh_token");
    navigate("/login");
  };

  return { customLogout };
}

export default useCustomLogout;
