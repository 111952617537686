import "../App.css";
import axios from "axios";

function useTwitter() {
  const urlEndpoint = process.env.REACT_APP_FISH_BE_ENDPOINT;
  const fishQuality = {
    1: "Common",
    2: "Rare",
    3: "Epic",
    4: "Legendary",
  };
  const postTweet = async (fishData) => {
    const tweetText = `I caught a ${fishQuality[fishData.quality]} Fish - ${
      fishData.fishName
    } fish ✌️ \nJoin with me https://fishingfrenzy.co/ #fishing_frenzy`;
    intentTweet(tweetText);
  };

  const intentTweet = (tweetText) => {
    const intentUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}`;
    window.open(intentUrl, "_blank");
  };

  const updateTwitterInfo = async (twitterSubject) => {
    let url_postTweet = urlEndpoint + "/twitter/update-twitter-info";
    try {
      const response = await axios.post(
        url_postTweet,
        {
          twitter: twitterSubject
            ? {
                name: twitterSubject.name,
                avatar: twitterSubject.profilePictureUrl,
                username: twitterSubject.username,
              }
            : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("fishAuth")}`,
          },
        }
      );
      console.log("Update twitter info successfully:", response.data);
    } catch (error) {
      console.error("Error update twitter:", error);
    }
  };

  return {
    postTweet,
    intentTweet,
    updateTwitterInfo,
  };
}
export default useTwitter;
