import { PrivyProvider, addRpcUrlOverrideToChain } from "@privy-io/react-auth";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GlobalProvider } from "./context";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { base, baseSepolia, mainnet } from "viem/chains";
import "./config/firebase";

let baseCustomRPC;

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  baseCustomRPC = addRpcUrlOverrideToChain(
    base,
    process.env.REACT_APP_RPC_BASE_SEPOLIA
  );
} else {
  baseCustomRPC = addRpcUrlOverrideToChain(
    baseSepolia,
    process.env.REACT_APP_RPC_BASE_SEPOLIA
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <PrivyProvider
      appId={process.env.REACT_APP_PRIVY_APP_ID}
      config={{
        appearance: {
          walletList: ["metamask", "coinbase_wallet"],
        },
        defaultChain: baseCustomRPC,
        supportedChains: [mainnet, baseCustomRPC],
        embeddedWallets: {
          createOnLogin: "all-users",
          noPromptOnSignature: true,
          waitForTransactionConfirmation: true,
          priceDisplay: {
            primary: "native-token", // Defaults to 'fiat-currency'
            secondary: null, // Defaults to 'native-token'
          },
        },
      }}
    >
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </PrivyProvider>
  </React.StrictMode>
);

reportWebVitals();
